// 乘法
export const mathMul = function (arg1: number | string, arg2: number | string, d = 2) {
  const r1 = typeof arg1 === 'number' ? arg1.toString() : arg1
  const r2 = typeof arg2 === 'number' ? arg2.toString() : arg2
  const m = (r1.split('.')[1] ? r1.split('.')[1].length : 0) + (r2.split('.')[1] ? r2.split('.')[1].length : 0)
  const resultVal = Number(r1.replace('.', '')) * Number(r2.replace('.', '')) / Math.pow(10, m)
  return Number(resultVal.toFixed(d))
}

// 除法
export const mathDiv = function (arg1: number | string, arg2: number | string, d = 2) {
  const r1 = typeof arg1 === 'number' ? arg1.toString() : arg1
  const r2 = typeof arg2 === 'number' ? arg2.toString() : arg2
  const m = (r2.split('.')[1] ? r2.split('.')[1].length : 0) - (r1.split('.')[1] ? r1.split('.')[1].length : 0)
  const resultVal = Number(r1.replace('.', '')) / Number(r2.replace('.', '')) * Math.pow(10, m)
  return Number(resultVal.toFixed(d))
}

// 加法
export const mathAdd = function (arg1: number | string, arg2: number | string, d = 2) {
  const r1 = typeof arg1 === 'number' ? arg1.toString() : arg1
  const r2 = typeof arg2 === 'number' ? arg2.toString() : arg2
  const d1 = r1.split('.').length === 2 ? r1.split('.')[1] : ''
  const d2 = r2.split('.').length === 2 ? r2.split('.')[1] : ''
  const maxLen = Math.max(d1.length, d2.length)
  const m = Math.pow(10, maxLen)
  const result = Number(((Number(r1) * m + Number(r2) * m) / m).toFixed(maxLen))
  return Number((result).toFixed(d))
}

/** 减法 */
export const mathSub = function (arg1: number | string, arg2: number | string, d = 2) {
  return mathAdd(arg1, -Number(arg2), d)
}
