

import { Vue, Component, Watch, Ref } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'
import { Warehousing, MaterialType, Warehouse, Material, DetailedList } from '@/types/material.d'
import { Page } from '@/types/common.d'
import { mathMul } from '@/utils/math'

@Component({ name: 'WarehousingAdd' })
export default class extends Vue {
  @Ref('form') readonly form!: ElForm
  formData: Warehousing = {
    projectId: '',
    storageDate: '',
    storageExplain: '',
    detailedList: []
  }

  props = {
    value: 'materialTypeId',
    label: 'materialTypeName',
    children: 'childNode',
    emitPath: false
  }

  materialTypeData: MaterialType[] = []
  materialData: Material[] = []
  warehouseData: Warehouse[] = []

  loading = false

  rules = {
    projectId: [
      { required: true, message: '请选择所属项目', trigger: 'blur' }
    ],
    storageDate: [
      { required: true, message: '请选择入库日期', trigger: 'blur' }
    ],
    detailedList: [
      { required: true, message: '请添加入库物料明细', trigger: 'change' }
    ]
  }

  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.loadMaterialType()
  }

  changeMaterialType (row: DetailedList) {
    row.materialId = ''
    row.materialName = ''
    row.materialCode = ''
    row.company = ''
    row.specifications = ''
    row.operationStock = ''
    row.warehouseId = ''
    row.materialList = []
    row.unitPrice = ''
    row.totalPrice = ''
  }

  loadMaterialData (type: string, row: DetailedList, value: string) {
    if (!value) {
      this.materialData = []
    } else {
      const params = {
        materialTypeId: row.materialTypeId,
        materialName: '',
        materialCode: '',
        state: '1'
      }
      if (type === 'materialName') {
        params.materialName = value
      }
      if (type === 'materialCode') {
        params.materialCode = value
      }
      this.loading = true
      this.$axios.get<Page<Material>>(this.$apis.material.selectMaterialByList, params).then(res => {
        // this.materialData = res.list
        row.materialList = res.list
      }).finally(() => {
        this.loading = false
      })
    }
  }

  deleteDetailedList (index: number) {
    this.formData.detailedList.splice(index, 1)
  }

  materialChange (materialId: string, row: DetailedList) {
    console.log('materialId', materialId)
    const item = (row.materialList as Material[]).find(data => data.materialId === materialId) as Material
    row.company = item.company
    row.materialCode = item.materialCode
    row.specifications = item.specifications
    row.materialTypeId = item.materialTypeId
  }

  loadMaterialType () {
    this.$axios.get<MaterialType[]>(this.$apis.material.selectMaterialTypeByList).then(res => {
      this.materialTypeData = res
    })
  }

  @Watch('formData.projectId')
  loadWarehouseData () {
    this.formData.detailedList.forEach(item => {
      item.warehouseId = ''
    })
    this.$axios.get<Page<Warehouse>>(this.$apis.material.selectWarehouseByList, {
      projectId: this.formData.projectId
    }).then(res => {
      this.warehouseData = res.list
    })
  }

  submit () {
    const form = JSON.parse(JSON.stringify(this.formData)) as Warehousing
    this.form.validate(valid => {
      if (valid) {
        const setItems = new Set(form.detailedList.map(item => (item.warehouseId as string) + item.materialCode))
        if (setItems.size !== form.detailedList.length) {
          this.$message({ type: 'warning', message: '当前入库物料明细已存在相同记录，不可添加' })
          return
        }
        const items = []
        for (let i = 0; i < form.detailedList.length; i++) {
          const item = form.detailedList[i]
          if (!/^[1-9][0-9]{0,8}$/.test(item.operationStock)) {
            this.$message({ type: 'warning', message: '入库数量为正整数' })
            return
          } else if (!item.warehouseId) {
            this.$message({ type: 'warning', message: '请选择入库仓库' })
            return
          } else if (item.unitPrice && item.totalPrice === '') {
            this.$message({ type: 'warning', message: '入库单价为整数或三位以内小数' })
            return
          } else {
            items.push({
              warehouseId: item.warehouseId,
              materialId: item.materialId,
              operationStock: item.operationStock,
              unitPrice: item.unitPrice,
              totalPrice: item.totalPrice
            })
            // 单价为空不传字段
            if (item.unitPrice === '') {
              delete items[items.length - 1].unitPrice
              delete items[items.length - 1].totalPrice
            }
          }
        }
        form.detailedList = items
        this.$axios.post(this.$apis.material.insertStorage, form).then(() => {
          this.$router.back()
        })
      }
    })
  }

  addMaterial () {
    this.formData.detailedList.push({
      materialTypeId: '',
      warehouseId: '', // 仓库id
      materialName: '',
      materialCode: '',
      materialId: '', // 物料id
      company: '',
      specifications: '',
      operationStock: '', // 入库数量
      unitPrice: '', // 单价
      totalPrice: '' // 总价
    })
  }

  // 单价、数量改变
  getPrice (row: DetailedList) {
    const regNeg = /^([1-9][0-9]*|[0-9])(\.\d{0,3})?$/
    row.unitPrice = row.unitPrice ? String(parseFloat(row.unitPrice)) === 'NaN' ? '' : String(parseFloat(row.unitPrice)) : ''
    if (row.unitPrice && row.operationStock && regNeg.test(row.unitPrice)) {
      row.totalPrice = String(mathMul(row.unitPrice, row.operationStock, 3)) === 'NaN' ? '' : String(mathMul(row.unitPrice, row.operationStock, 3))
    } else {
      row.totalPrice = ''
    }
  }
}
